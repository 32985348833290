@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.parent_main{
  background:white;
  width:100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  gap:22px;
}
.title_main{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.update_theme{
  display: flex;
  gap: 5px;
  align-items: center;
  border: none;
  color: white;
  background: var(--color-secondary);
  border-radius: 5px;
  padding:8px;
  cursor: pointer;
}
.update_theme1{
  display: flex;
  gap: 5px;
  align-items: center;
  border: none;
  color: white;
  background: var(--color-secondary);
  border-radius: 5px;
  padding:8px;
  cursor: pointer;
}
.update_theme1 :nth-child(1){
  font-size:18px;
  font-weight: 550;
  transform: translate3d(0,1px,0);
}

.update_theme :nth-child(1){
  font-size:18px;
  font-weight: 550;
  transform: translate3d(0,1px,0);
}
.update_theme :nth-child(2){
  font-size:13px;
  font-weight: 550;
  transform: translate3d(0,1px,0);
}

.alls_thematics{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.sous_alls_thematics{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: calc(100% - 220px) !important; 
    justify-content: space-between !important;  
  /* width:calc((100% / 5) - 125px / 5); */
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
   background-color:#0084FA0D; 
  align-items: center;
border: 1px solid #0084FA1A;
/* position: relative; */
}
.sous_alls_grammaire{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: calc(100% - 220px) !important;
    /* justify-content: space-between !important;   */
  align-items: center;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
   background-color:#0084FA0D; 
  align-items: center;
border: 1px solid #0084FA1A;
/* position: relative; */
}
.remove_words{
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
  display: flex;
   margin: auto; 
   transform: translate3d(10px,0,0);
}
.parent_icons_thematics_span1{
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
}

.parent_icons_thematics{
  display: flex !important;
  gap: 5px !important;
  align-items: center;
}
.parent_icons_thematics_child1{
  color: white;
  padding: 10px;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50% !important;
}
.parent_icons_thematics_child2{
  font-size: 14px !important;
  cursor: pointer;
  width: calc(100% -30px) !important;
}
.parent_icons_thematics_child2_grammar{
  font-size: 14px !important;
  cursor: pointer;
  color:"#0084FA";
  font-weight: bold;
}
.parent_icons_grammaire_child2{
  align-items: center;
  display:flex;
  gap:15px !important;
  width: calc(100% - 30px) !important;
}
.optionGrammar{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
   width:22.5em !important; 
  display: flex;
  flex-direction: column;
  background:white;
  position: absolute;
  /* transform: translate3d(0,64px,0); */
  border: 1px solid rgba(0,0,0,0.05);
  z-index: 2000;
  max-height:200px !important;
  /* overflow-y: auto; */
}
.optionGrammar span{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  text-align: left;
  color:black;
  border-bottom: 1px solid #fafafa;
   opacity: 0.8;
   font-size: 16px;
   transition: 1s;
   cursor: pointer;  
}
.optionGrammar span:hover{
  background:var(--color-secondary);
  color: white;
}
.optionGrammar1{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
   width:22.5em !important; 
  display: flex;
  flex-direction: column;
  background:white;
  position: absolute;
   transform: translate3d(0,4em,0); 
  border: 1px solid rgba(0,0,0,0.05);
  z-index: 5000;
  max-height:200px !important;
   overflow-y: auto; 
}
.errorGrammar{
  position:absolute;
  bottom: 0px;
  left: 0;
  color:"green"
}
.optionGrammar1 span{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  text-align: left;
  color:black;
  border-bottom: 1px solid #fafafa;
   opacity: 0.8;
   font-size: 16px;
   transition: 1s;
   cursor: pointer;  
}
.optionGrammar1 span:hover{
  background:var(--color-secondary);
  color: white;
}


.child-grammaire{
  display:flex;
  align-items: center;
  gap: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 5px;
  border:1px solid #0084FA;
  cursor:pointer;
}
.color_grammaire{
  color:#0084FA
}
.next_words{
  width:12px !important;
  height:12px !important;
  object-fit:cover;
}
.nameDetails{
  color:#0084FA !important;
  font-size: 12px !important;
}
.parent_icons_thematics_span{
  font-size: 14px;
   text-align: left; 
   transform: translate3d(40px,0,0);
}

.parent_messages3_thematics{
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
  align-items: center;
  background: white;
  cursor: pointer;
  width: 120px;
  transform: translate3d(70px,0,0);
}
.repondre_thematic{
  font-size: 14px;
}
#masqueTheme{
  position: fixed;
  top: 0;
  bottom:0;
  left: 0;
  right: 0;
  background:black;
  opacity: 0.8;
  z-index: 5;
  height: 100vh !important;
  overflow-y: auto; 
}
#answer_client_theme{
background: white;
  width: 80%;
  margin: auto;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  border-radius: 5px;
  z-index: 2000;
   position:absolute; 
  top:20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 30px !important;
  padding-bottom: 30px;
}
#answer_client_theme_grammaire{
  background: white;
    width: 60%;
    margin: auto;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    border-radius: 5px;
    z-index: 2000;
     position:absolute; 
    top:20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 30px !important;
    padding-bottom: 30px;
  }
.answer_client_theme1{
  color: black;
  font-weight: 550;
}
.parent_contenair_sous_thematique{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.contenair_sous_thematique{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.element_none{
  color: transparent;
}
.parent_sous_thematique{
  padding: 15px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #0084FA0D;
  border-radius: 5px;
}
.space_contenair{
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.space_contenair input{
  width: 100%;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  background: white;
  border: 1px solid #bebebe;
}
.space_contenair label{
  font-size: 14px;
  color: black;
  font-weight: 550;
  text-align: left;
}
.textarea_sous_thematique{
  border: none;
  outline: none;
  background: white;
  resize: none;
}
::placeholder{
  color:#bebebe;
  font-size: 12px;
}
textarea::placeholder{
  color:#bebebe !important;
  font-size: 13px !important;
  font-family: "Inter" !important;
}
.add_remove{
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translate3d(0,-5px,0);
}
.parent_remove{
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
}
.parent_remove :nth-child(2){
 font-size: 12px;
}
.title{
  color: black;
  font-weight: 550;
}
.remove_add{
  width: 15px;
  height: 15px;
}
 .add_theme{
  cursor: pointer;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 550;
  font-size: 12px;

}
.add_theme :nth-child(2){
text-decoration: underline;
} 
.answer_client_theme2{
  width: 100%;
  display: flex;
  gap: 20px;
  align-items:center;
}
.space-signup{
  position: relative;
  width: 50% !important;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.img_user{
  color:#42444B;
  position: absolute;
  top:10px;
  right:10px;
}
.space-signup label{
  color:black;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
}
.space-signup input{
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  outline: none;
  transition: ease-in-out 0.2s;
}
.selectGrammar{
  width: 100%;
}
.answer_client_theme2_grammaire{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.answer_client_theme2_child1{
  width: calc(100% - 220px);
  display: flex;
  gap: 20px;
  align-items: center;
}
.answer_client_theme2_child1_left{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
  position: relative;
}
.answer_client_theme2_child1_left label{
  text-align: left;
  font-size: 15px;
  color: #42444B;
  font-weight: 550;
}
.answer_client_theme2_child1_left input{
  border-radius: 5px;
  border: 1px solid #bebebe;
  padding-left:10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
.answer_client_theme2_child2{
  width: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
}
.circle{
  width:24px;
  height:24px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 5px; 
}
.icon_language{
  color:black !important;
  position: absolute !important;
  right: 10px !important;
  width: 15px;
  height: 15px;
}

.parent_palette_color{
  position: absolute;
  z-index: 2;
  top:110%;
  border-radius: 5px;
}
.parent_textarea{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: white;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  position: relative;
}
.nbre_words{
  position: absolute;
  right:15px !important;
  bottom: 10px;
  font-size: 13px;
}
.option_chips{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 10px;
}
.chip{
  display: flex;
  gap: 15px;
  padding-right: 10px;
  padding-left: 10px;
  padding-top:5px;
  padding-bottom: 5px;
  border-radius: 20px;
  cursor: pointer;
  align-items: center;
  border: 1px solid #bebebe;
}
.close_chips{
  width: 12px;
  height: 12px;
  color: #42444B;
  border: 1px solid #42444B;
  border-radius: 50%;
}
.chip span{
  font-size: 12px;
  color:#090A0E;
  font-weight: 650;
}


.icons_font_awesome{
  width:18px;
  height:18px;
  padding: 10px;
}
.close_color{
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.answer_client_theme2_child2 label{
  text-align: left;
  font-size: 15px;
  color: #42444B;
  font-weight: 550;
}
.answer_client_theme2_child2 input{
  border-radius: 5px;
  border: 1px solid #bebebe;
  padding-left:40px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  font-size: 12px;
}
.close_theme{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 15px;
  height: 15px;
  color: grey;
  cursor: pointer;
}
.close_edit{
  width: 15px;
  height: 15px;
  color:black;
  cursor: pointer;
}
.error_theme{
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -20px;
}
.parent_answer_client_theme3_visibility{
  width: 100%;
  display: flex;
  gap: 40px;
}
.visibility{
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* width: 20%; */
}
.visibility label{
  font-size: 15px;
  color: #42444B;
  font-weight: 550;
  text-align: left !important;
}
.answer_client_theme3{
  width: 80%;
  display: flex;
  gap: 10px;
  align-items: center;
}
.answer_client_theme3 label{
  font-size: 15px;
  color: #42444B;
  font-weight: 550;
}
.answer_client_theme3_parent_input{
  position: relative;
  width: 100%;
}
.answer_client_theme3_parent_input input{
  width: 100%; 
  border-radius: 5px;
  border: 1px solid #bebebe;
  padding-left:10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
.answer_client_theme3_search{
  position:absolute;
  right: 0;
  top:0px;
  background: var(--color-primary);
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  height:100%;
  padding-top: 10px;
  cursor: pointer;
}
.search_theme{
  width: 18px;
  height:18px;
  object-fit: cover;
}
.answer_client_theme4{
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  height:200px;
  overflow-y: auto;
  border: 1px solid #0084FA1A;
  box-shadow: 0px 4px 4px 0px #0084FA1A;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.button_theme{
  width: 100%;
  background: var(--color-primary);
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  border: none;
  color: white;
  text-align:center ;
  font-size: 15px;
  border-radius: 5px;
  cursor: pointer;
}
/******edit name****/
.edit_button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.edit_button:hover {
  background-color: #0056b3;
}

/* Popup modale */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 40%;
 justify-content: center;
 display: flex;
 flex-direction: column;
 gap: 20px;
}

.modal_content input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal_actions {
  display: flex;
  justify-content: space-between;
}

.cancel_button {
  background-color: #ccc;
  color: black;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.save_button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.save_button:hover {
  background-color: #218838;
}
.edit_icon {
  cursor: pointer !important;
  color: #888;
  width: 13px;
  height: 13px;
   transform: translate3d(150px,0,0);
}
.edit_icon_grammar {
  cursor: pointer !important;
  color: #888;
  width: 13px;
  height: 13px;
}
.headerEdit{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.paletteEdit{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inputEdit{
  outline: none;
}