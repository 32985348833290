.parent_main{
    width:100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    gap:22px;
  }
  /* .parent_header_sous_thematiques{
    transform: translate3d(0,10px,0);
  } */
  .sous_parent_main_sous_theme{
    background:white;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .sous_parent_main_users_main{
    display:grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
  }
  .sous_parent_main_users_main_bloc{
    padding:10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    border:1px solid #bebebe;
    cursor: pointer;
  }
  .sous_parent_main_users_main_bloc1{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sous_parent_main_users_main_bloc1 :nth-child(1){
    color: red;
    font-size: 18px;
  }
  .sous_parent_main_users_main_bloc1 :nth-child(2){
    
    font-size: 12px;
    color: grey !important;
  }
  .sous_parent_main_users_main_bloc2{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sous_parent_main_users_main_bloc2 :nth-child(1){
   font-size:14px;
   color: grey !important;
  }
  .sous_parent_main_users_main_bloc2 img{
    width:18px;
    height:18px;
  }
  .nav_link{
    text-decoration:none !important;
  }
   
  
  #masqueSousTheme{
    position: fixed;
    top: 0;
    bottom:0;
    left: 0;
    right: 0;
    background:black;
    opacity: 0.8;
    z-index: 5;
    height: 100vh !important;
    overflow-y: auto;
  }
  .sous_parent_main_users_header_input_sous_thematique{
    width: calc(100% - 220px) !important;
    position: relative;
  }
  .selectSousThematique{
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  .selectSousThematique option{
    width: 200px !important;
    background: red !important;
  }