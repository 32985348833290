.icons_project{
    width: 15px;
    height: 15px;
    color: white;
}
.sous_icons_infos{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.theme_projects{
    color: white;
    font-size: 14px;
}
.sous_icons_infos :nth-child(1){
    font-size: 10px;
    color: white;
}