@import url('../../../../index.css');
.header{
    padding-top:13px;
   padding-bottom:13px;
   padding-left: 30px;
   padding-right: 30px; 
   background-color:var(--color-primary);
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 .header_main{
  display: flex !important;
  gap: 5px !important;
  align-items: center !important;
 }
 .header_main_1{
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  cursor: pointer;
 }
 .parent_notifications{
  width: 30px;
  height: 30px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: translate3d(0,2px,0);
}
.parent_notifications img{
  width: 15px;
  height: 15px;
  cursor: pointer;
}

 .counter_notifications{
  color: white !important;
  position: absolute;
  font-size:7px;
  top:2px;
  right: 4px;
  background: #D73030;
  padding:2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 50%;
 }
 .header_main_1 :nth-child(1){
  font-size:23px;
 color: black;
 font-weight: 550;
 } 
 .header_main_1 :nth-child(2){
  font-size:23px;
  color:var(--color-primary);
  font-weight: 550;
 } 
  .header_main :nth-child(1){
 font-size:23px;
 color: black;
 font-weight: 550;
 }
 .header_main :nth-child(2){
  font-size:23px;
  color:var(--color-primary);
  font-weight: 550;
  }
 .header_left{
  display: flex;
  gap: 10px;
  align-items: center;
}
.header_left img{
  width: 180px !important;
  height: 40px;
  object-fit: cover;
  transform: translate3d(0,4px,0);
}
/* .header_left :nth-child(1){
  width: 30px;
  height: 30px;
}
.header_left :nth-child(2){
  width: 200px;
  height: 40px;
  object-fit: cover;
} */
.header_main_left{
  display: flex;
  align-items: center;
  gap: 5px;
}
.header_right{
  display: flex;
  gap: 25px;
  align-items: center;
}
.header_main_1{
  color: grey !important;
  font-size: 14px !important;
  cursor: pointer;
}
/*.header_main_1-span{
  color: grey !important;
   font-size: 14px !important;
  cursor: pointer;
  transform: translate3d(0,-15px,0) !important; 
} */
 .header_main_sous__parent_thematique{
  display: flex;
  justify-content:space-between;
  align-items:center;
 }
.header_main_sous_thematique{
  color: grey !important;
  font-size: 14px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}
.update_theme_sous_thematique{
  display: flex;
  gap: 5px;
  align-items: center;
  border: none;
  color: white;
  background: var(--color-secondary);
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  /* font-size: 1px; */
}
.update_theme_sous_thematique :nth-child(1){
   font-size:18px; 
  font-weight: 550;
  transform: translate3d(0,1px,0);
}
.update_sous_thematique{
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}



.header_main_2_icons_mots_croisés{
  width:12px;
  height: 12px;
  color: red !important;
  transform: translate3d(-3px,0px,0);
}
 .header_remove_worlds{
  font-size: 13px !important;
  color: #FFFFFF !important;
  text-align: center;
  padding: 10px !important;
  background: #24D26D;
  border:none;
  border-radius: 5px;
  cursor: pointer;
} 
.header_main_3{
  font-size:23px;
 color: black;
 font-weight: 550;
 transform: translate3d(-3px,0px,0);
 outline: none;
}
.update_buton{
  background:var(--color-secondary);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  cursor: pointer;
  transform: translate3d(5px,0px,0);
}

.photo_profil{
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover !important;
}

