
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}
*,*::before,*::after{

  box-sizing: border-box;
}

.App{
  display: flex;
  flex-direction: column;
}
:root {
  --color-primary: #0084FA;
  --color-secondary: #24D26D;
}
::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6D4F8 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:var(--color-secondary);
}
