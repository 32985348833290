.parent_main{
  background:white;
  width:100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  gap:22px;
}
.sous_parent_main_users{
  background:white;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.sous_parent_main_users_header{
  display: flex;
  gap: 20px;
}
.sous_parent_main_users_header_input{
  width: calc(100% - 220px) !important;
  position: relative;
}

.input_users{
  width: 100%; 
  border-radius: 5px;
  border: 1px solid #BEBEBE;
  padding-left:10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
.select{
  display: flex;
  flex-direction: column;
  width: 200px;
}
.parent_abonnes{
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
 justify-content: space-between;
  border: 1px solid #BEBEBE;
  outline: none;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  width:100%;
}
.parent_abonnes_language{
  display: flex;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 25px;
  padding-right: 15px;
 justify-content: space-between;
  border: 1px solid #BEBEBE;
  outline: none;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  width:100%;
  position: relative !important;
}
.icon_language_img{
  width: 15px;
  height: 15px;
  position: absolute;
  left: 5px;
}
.parent_abonnes_language span{
  font-size: 12px;
}
.parent_abonnes span{
 font-size: 14px;
}
.down_user{
  width: 14px;
  height: 14px;
}

.icon_zIndex{
  color: #909090;
/* z-index: 1000; */
}
.option{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 200px !important;
  display: flex;
  flex-direction: column;
  background:white;
  position: absolute;
  /* transform: translate3d(0,64px,0); */
  border: 1px solid rgba(0,0,0,0.05);
  z-index: 2000;
  max-height:200px !important;
  /* overflow-y: auto; */
}
.option_language{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  background:white;
  position: absolute;
  /* transform: translate3d(0,64px,0); */
  border: 1px solid rgba(0,0,0,0.05);
  z-index: 2000;
  max-height:150px !important;
}
.option_language span{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  text-align: left;
  color:black;
  border-bottom: 1px solid #fafafa;
   opacity: 0.8;
   font-size: 16px;
   transition: 1s;
   cursor: pointer; 
}
.option span{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  text-align: left;
  color:black;
  border-bottom: 1px solid #fafafa;
   opacity: 0.8;
   font-size: 16px;
   transition: 1s;
   cursor: pointer;  
}
.option span:hover{
  background:var(--color-secondary);
  color: white;
}
.option_language span:hover{
  background:var(--color-secondary);
  color: white; 
}
.parent_search_users{
  position:absolute;
  right: 0;
  top:0px;
  background: var(--color-primary);
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  height:100%;
  padding-top: 10px;
  cursor: pointer;
}
.search_users{
  width: 18px;
  height:18px;
  object-fit: cover;
}
.sous_parent_main_users_information{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
.sous_parent_main_users_information-child1{
  width: calc(100% - 220px) !important;
  display: flex;
 justify-content: space-between; 
 padding-left: 10px;
}
.sous_parent_main_users_information-child1 span{
  width:calc((100% / 4) - 125px / 4);
  color: black;
  font-size:14px;
  font-weight: 550;
}
.sous_parent_main_users_information-child2{
  /* width: 100%; */
  width: calc(100% - 220px) !important;
  display: flex;
 justify-content: space-between; 
 background: #0084FA0D;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
}
.sous_parent_main_users_information-child2 div{
  width:calc((100% / 4) - 125px / 4);
  cursor: pointer;
}
.prenom{
  display:flex;
  gap:5px;
  align-items: center;
}
.prenom img{
  width: 30px;
  height: 30px;
}
.prenom span{
  color:black !important;
  font-size: 800 !important;
  font-size: 15px;
}
.user_adress_mail{
  color:#42444B;
  font-size: 13px;
}
.user_inscription{
  color:#42444B;
  font-size: 14px;
  transform: translate3d(7px,0,0);
}
.status{
  font-size: 14px;
   transform: translate3d(7px,0,0); 
}
.status-abonne{
  color: var(--color-primary) !important;
}
.action{
  display:flex;
justify-content: space-between;
   width:120px !important;
  background:white !important;
  align-items:center;
  padding: 5px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
   transform: translate3d(5px,0,0); 
  
}
.action img{
  width: 15px;
  height: 15px;
}
.action span{
  font-size:14px;
}
.pagination{
  display: flex;
  justify-content: space-between;
}
.pagination_nbrs_pages{
  font-size: 12px;
}
.counter{
  display: flex;
  gap: 5px;
}
.direction_icons{
   display: flex; 
   gap: 10px; 
   align-items: center;
   cursor: pointer;
   height: 30px;
}
.icons_pagination{
  color: #42444B;
}
.non_active{
  color: #42444B;
  padding-left: 10px;
  padding-right: 10px;
  height:30px !important;
  line-height: 30px;
}
.active_page{
  color: white;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background: var(--color-primary);
  height:30px !important;
  line-height: 30px;

}