.parent_main{
    background:white;
    width:100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 45px;
    padding-bottom:40px;
    display: flex;
    flex-direction: column;
    gap:22px;
    /* max-height: 100vh !important; */
  }
  .sous_parent_main_comptes{
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* height:700px!important;
    overflow-y: auto !important; */
  }
  .sous_parent_main1{
    width: 100%;
    background:url("../../../assets/images/rectangle.png");
    border-radius: 5px;
    background-size: cover;
    background-position:center;
   background-repeat:no-repeat;
   height:300px !important;
   position: relative;
  }
  .sous_parent_main1 img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width:85px;
    height:85px;
    border-radius: 50%;
    object-fit: cover;
   
  }
  .update_file{
    width:65px !important;
    height:65px !important;
    border-radius: 50%;
    object-fit: cover !important; 
  }

  .checkpictures{
    position: absolute;
    width: 21px !important;
    height: 21px !important;
    object-fit: cover;
    left: 52% !important;
    top: 62% !important;
    cursor: pointer;
  }
  .sous_parent_main2{
    width: 100%;
    border: 1px solid #BEBEBE;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .sous_parent_main2_header{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .info{
    transform: translate3d(0,-10px,0) !important;
  }
  .update_info_user{
    display: flex;
    padding: 7px;
    gap: 5px;
    border: 1px solid #BEBEBE;
    outline: none;
    border-radius: 5px;
    align-items: center;
    cursor: pointer;
  }
  .update_info_user img{
    width: 15px;
    height: 15px;
  }
  .sous_parent_main2_main{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .space_infos{
    display: flex;
    flex-direction: column;
    gap: 5px;
   
  }
  .space_infos label{
    text-align: left;
    font-weight: 550;
  }
  .space_infos input{
    border: 1px solid #BEBEBE;
    padding: 10px;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .sous_parent_main3{
    width: 100%;
    padding-left:20px ;
    padding-right:20px ;
    padding-top:25px;
    padding-bottom: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    border: 1px solid grey;
  }