.sous_parent_main_croisés{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.title_words{
    font-weight: 550;
    font-size: 14px;
    color: black;
}
.sous_parent_main_croisés_left{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title_parent{
    font-weight: 550;
    font-size: 14px;
    color: #D73030;
}
.parent_reste{
    display: flex;
    flex-direction: column;
    gap: 15px;
    transform: translate3d(0,30px,0);
    padding-bottom: 30px;
}
.content_title{
    padding: 10px;
    font-size: 14px;
    border: none;
    outline: none;
}
.content_french{
    padding: 10px;
    font-size: 14px;
    border: none;
    outline: none;
}
.reste{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sous_parent_main_croisés_left_parent{
    display:grid;
   grid-template-columns: 1fr;
    gap: 5px !important;
    height: 375px;
    overflow-y: auto;
}
.sous_parent_main_croisés_left_1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius:5px;
     background: #0084FA0D; 
    color: black;
    font-weight: 550;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
}


.sous_parent_main_croisés_left_2{
    padding-top: 10px;
    padding-bottom: 10px; 
    display:grid;
      grid-template-columns: 1fr 1fr 1fr; 
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    border-radius: 5px !important;
    font-size: 14px;
    border: 1px solid #D6EBFF;
      align-items: center;  
}
.content{
    color: black;
    padding: 2px !important; 
    border:none;
    outline: none;  
}
.content_words{
   padding: 2px !important;
   border:none;
    outline: none;
    /* transform: translate3d(30px,0,0); */
}
 .content:focus{
    border: 1px solid #bebebe !important;
    border-radius: 5px;
} 
.content_words:focus{
    border: 1px solid #bebebe !important;
    border-radius: 5px;
}
.disable{
    color: #909090;
    padding: 2px;
}
.sous_parent_main_croisés_right{
   display: flex;
   gap: 20px !important;
   flex-direction: column;
   /* background: red !important; */
}
  .sous_parent_main_croisés_contenair{
    /* display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;  */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #D6EBFF;
    text-align: center;
    cursor: pointer;
}
  /*.sous_parent_main_croisés_contenair {
    width: 100%;
    display: flex;
    flex-wrap: wrap;  
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
     border-radius: 5px; 
    border: 1px solid #D6EBFF;
    text-align: center;
    gap:px;
    cursor: pointer;
  }
  
  .cell {
    width: 30px;
    height: 30px;
    padding: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius:5px;
    border: 1px solid var(--Grey, #42444B);
    color: #42444B;
    outline: none;
     text-align: center;
  }  */
.title_language{
    font-weight: 700 !important;
}
.title_languageAnglais{
    font-weight: 700 !important;
    transform: translate3d(-4em,0,0);
}
.title_languageFrancais{
    font-weight: 700 !important;
    transform: translate3d(-8.5em,0,0);
}

 .sous_parent_main_croisés_contenair  input{
    width: 100% !important; 
    height: 100% !important;
    padding: 2px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius:5px;
    border: 1px solid var(--Grey, #42444B);
    color: #42444B;
    outline: none;
     text-align: center;
}
.save{
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    color: white;
    border: none;
    background: var(--Secondary, #24D26D);
    font-size: 14px;
    cursor: pointer;

}
.boldGras{
    font-size: 700;
    color:#24D26D;
    
}

