.parent_traduction{
    display: flex;
    gap: 40px;
    width: 100%;
}
.child_traduction{
   width:45%; 
   display:flex;
   flex-direction:column;
   gap:20px;
}
.sous_child_traduction1{
    position: relative !important;
    border: 1px solid #D6EBFF;
    background: #D6EBFF;
    border-radius:5px;
    padding: 15px;
}
.sous_child_traduction2{
    width:45%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.remove_traduction{
    width: 15px;
    height:15px;
    object-fit: contain;
     position: absolute !important;
     top:15px !important;
    right: 15px !important;
    cursor: pointer;  
    /* left:15 !important; */
}
.level-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #D6EBFF;
    cursor: pointer;
}
.level-header_name{
    font-size: 13px;
    color: #000000;
    font-weight: bold;
}
.downTraduction{
    width: 13px;
    height:13px;
    object-fit: contain;
}
.sentenceIndex{
    background: #000000;
      width:20px;
    height:20px; 
     line-height: 20px;  
    border-radius:50%;
    color: white;
   text-align: center;
    font-size: 12px;
}
.sentences-container{
    border:1px solid #D6EBFF;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
}
.french-sentence{
    display: flex;
    gap: 5px;
}
.sentence{
    font-weight:600;
    font-size: 14px;
    color:"#000000";
}
.sentence:focus{
    outline:none
}
.editSentences{
margin-left:20px;
padding: 10px;
border-radius: 5px;
width: calc(100% - 20px);
border: 1px solid #D6EBFF;
background: #D6EBFF;
font-size: 12px;
}
.editSentences:focus{
    outline:none
}
.add-sentence-button{
    display: flex;
    justify-content: space-between;
    transform:translate3d(0,10px,0) ;
    cursor: pointer;
}
.plusTraduction{
    font-size: 18px;
    color:#24D26D;
    font-weight: bold;
}
.sous_child_traduction_span:focus{
    outline: none;
}
.save-button{
    width: 100%;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius:5px;
    outline: none;
    background: #24D26D;
    color: white;
    border:none;
    font-size: 13px;
}
.level-section{
    display:flex;
    flex-direction: column;
    gap: 20px;
}
.sentence-block{
    display:flex;
    flex-direction: column;
    gap: 10px; 
    padding-top:15px;
}
 .downTraduction {
         transition: transform 0.3s ease;
     }
    
     .downTraduction.rotated {
         transform: rotate(180deg);
     }
     #masqueTheme{
        position: fixed;
        top: 0;
        bottom:0;
        left: 0;
        right: 0;
        background:black;
        opacity: 0.8;
        z-index: 5;
        height: 100vh !important;
        overflow-y: auto; 
      }