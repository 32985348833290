/*Snackbar style*/
#snackbar {
    position: fixed;
    left: 15px;
    bottom: 15px !important;
    background:#212121;
    color: #fff;
    padding: 15px;
    border-radius: 0px 3px 3px 0px;
    border-left: 4px solid var(--color-primary);
    transform: scale3d(0, 0, 0);
    font-size: 0.9rem;
    z-index: 9999999999;
    transition: transform ease 0.3s !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    contain: layout;
  }
  #snackbarImg {
    margin-right: 3px;
    vertical-align: top !important;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  #actionSnackbar {
    text-align: right;
    color: #D73030;
    margin-left: 50px;
    font-size: 0.9rem;
    cursor: pointer;
    display: inline-block !important;
    text-transform: uppercase;
  }
  .snackbar-visible {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 20px;
    padding: 10px 20px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    z-index: 1000;
  }