.parent_infos{
    padding: 15px;
    display: flex;
    gap: 20px;
     border: 1px solid var(--color-primary); 
    border-radius: 5px;
    background: #0084FA1A;
    align-items: center;
}

.parent_infos_right{
    display: flex;
    gap:5px;
    flex-direction: column;
}
.parent_infos_right :nth-child(1){
    color: black;
    font-weight: 550;
    font-size: 14px;
}
.parent_infos_right :nth-child(2){
    color: black;
    font-size: 12px;
}